<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <strong style="font-size: 16px;">
                  Pilih penerima
                </strong>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <a-button class="btn btn-outline-primary float-right" @click="showModal">{{ form.to.length === 0 ? 'Broadcast ke semua' : 'Kirim ke ' + form.to.length + ' penerima' }}</a-button>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <a-card size="small">
                  <a-card-grid
                    style="width: 100%; text-align: center; cursor: pointer;"
                    v-if="users.length === 0"
                  >
                    <span>
                      Tidak ada data users
                    </span>
                  </a-card-grid>
                  <a-card-grid
                    v-for="(record, index) in users"
                    :key="index"
                    style="width: 20%; text-align: center; cursor: pointer;"
                    :class="form.to.includes(record.id) ? 'bg-primary': ''"
                    @click="selectUser(record.id)"
                    v-else
                  >
                    <span style="cursor: pointer;" :class="form.to.includes(record.id) ? 'text-white': ''">
                      {{ record.name }}
                    </span>
                  </a-card-grid>
                </a-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <formModal ref="formModal" :key="componentKey"/>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import formModal from './modal'
export default {
  components: {
    formModal,
  },
  created () {
    this.getUsers()
  },
  data() {
    return {
      users: [],
      form: {
        to: [],
      },
    }
  },
  methods: {
    async getUsers() {
      var response = await lou.readMaster('user')
      this.users = response.data
    },
    showModal() {
      this.$refs.formModal.showModal(this.form.to)
    },
    selectUser(id) {
      // console.log('id', id)
      var target = this.form.to.indexOf(id)
      // console.log('target', target)
      if (target < 0) {
        // console.log('tambah')
        this.form.to.push(id)
      } else {
        // console.log('delete')
        this.form.to.splice(target, 1)
      }
      // console.log('this.form.to', this.form.to)
    },
  },
}
</script>

<style></style>
